import { useStore } from "@/store";

const store = useStore();

const commonUrl = "/api/common";
const customerUrl = "/api/customer";

let axios = window.axios;

export let Auth = {
  login(login, password) {
    let params = {
      controller: "account",
      task: "login",
      login,
      password,
    };

    return axios.post(commonUrl, params).then(function (response) {
      store.setAuthorised(response.data.result != "SESSION_NOT_FOUND");
      store.setUserInfo(response.data);
      return response.data;
    });
  },

  logout() {
    const params = {
      controller: "account",
      task: "logout",
    };

    return axios.post(commonUrl, params).then(function (response) {
      if (response.data.result == "Ok") {
        store.logout();
      }

      return response.data;
    });
  },

  register(data) {
    const params = {
      controller: "account",
      task: "create",
      ...data,
    };

    return axios.post(commonUrl, params).then((response) => response.data);
  },

  register_provider(data) {
    const params = {
      controller: "account",
      task: "reg_provider",
      ...data,
    };

    return axios.post(commonUrl, params).then((response) => response.data);
  },

  register_provider_ip(data) {
    const params = {
      controller: "account",
      task: "reg_provider_ip",
      ...data,
    };

    return axios.post(commonUrl, params).then((response) => response.data);
  },

  verify(login, code) {
    const params = {
      controller: "account",
      task: "verify_mobile",
      login,
      code,
    };

    return axios.post(commonUrl, params).then((response) => response.data);
  },

  verify_provider(reg_id, code) {
    const params = {
      controller: "account",
      task: "confirm_reg_provider",
      reg_id,
      code,
    };

    return axios.post(commonUrl, params).then((response) => response.data);
  },
};

export const Account = {
  get_balance() {
    const params = {
      controller: "account",
      task: "get_provider_balance",
    };

    return axios.get(commonUrl, { params }).then((response) => response.data);
  },
};

export let Org = {
  search(inn) {
    return axios.get(`/api/org/search/${inn}`).then(function (response) {
      return response.data;
    });
  },
  get_provider_by_inn(inn) {
    const params = {
      controller: "account",
      task: "get_provider_by_inn",
      inn: inn,
    };

    return axios.get(commonUrl, { params }).then((response) => response.data);
  },
  get_provider_address() {
    const params = {
      controller: "account",
      task: "get_provider_address",
    };

    return axios.get(commonUrl, { params }).then((response) => response.data);
  },
};

export const Search = {
  get_cities(city_name) {
    const params = {
      controller: "account",
      task: "get_cities",
      city_name,
    };

    return axios.get(customerUrl, { params }).then((response) => response.data);
  },
  get_streets(city_nmbr, street_name) {
    const params = {
      controller: "account",
      task: "get_streets",
      city_nmbr,
      street_name,
    };

    return axios.get(customerUrl, { params }).then((response) => response.data);
  },
  get_posts(post_name) {
    const params = {
      controller: "account",
      task: "get_post",
      post_name,
    };

    return axios.get(customerUrl, { params }).then((response) => response.data);
  },
};
