"use strict";

import Vue from "vue";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";

import { useStore } from "@/store";
import { bus } from "@/eventbus";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: { query: false },
});

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
  // adapter: cache.adapter,
};

if (process.env.NODE_ENV === "development") {
  // config.adapter = cache.adapter;
}

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    const result = response.data.result;
    if (result == "SESSION_NOT_FOUND" || result == "MISSING_USERSESSION") {
      const store = useStore();
      store.layout = "default";
      window.location.href = "/#/login";
      return response;
    }

    return response;
  },
  function (error) {
    // Do something with response error

    try {
      const params = JSON.parse(error.config.data);
      if (
        params?.controller == "order" &&
        params?.task == "get_waiting_demands"
      ) {
        return Promise.reject(error);
      }
    } catch (error) {
      return Promise.reject(error);
    }

    let reason = "";
    if (error.response) {
      switch (error.response.status) {
        case 500:
          reason = "Внутренняя ошибка сервера";
          break;
        case 502:
          reason = "Внутренняя ошибка сервера";
          break;
        case 503:
          reason = "Недоступна служба обработки запросов";
          break;
      }

      reason += ` (${error.response.statusText},  ${error.response.status}")`;
    } else {
      if (error.message == "Network Error") {
        reason = "Нет подключения к интернету";
      }
    }

    const message = `Не удалось выполнить операцию. ${reason}`;

    bus.$emit("server-error", {
      title: "Ошибка",
      message,
    });

    const store = useStore();
    store.postMessageQueue({
      date: new Date(),
      user: store.user_info,
      message,
    });

    return Promise.reject(error);
  }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export { _axios as axios };

export default Plugin;
