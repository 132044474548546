<template>
  <v-app>
    <component :is="layout" :authorised="authorised"></component>

    <dialogs-wrapper />
  </v-app>
</template>

<script>
import { DialogsWrapper } from "vue-modal-dialogs";
import { useStore } from "@/store";

const store = useStore();

export default {
  name: "App",
  components: { DialogsWrapper },
  computed: {
    layout() {
      let layout = store.layout;
      return () =>
        import(/* webpackChunkName: "layout" */ `@/layouts/${layout}.vue`);
    },
    authorised() {
      return store.authorised;
    },
  },
};
</script>

<style>
html {
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: inherit;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
</style>
