import Vue from "vue";
import frag from "vue-frag";
import { VueMaskDirective } from "v-mask";

Vue.directive("frag", frag);
Vue.directive("mask", VueMaskDirective);

Vue.directive("required", {
  bind: function (el, binding, vnode) {
    const add_sign = binding.value == undefined || binding.value;

    if (add_sign) {
      let sign = document.createElement("span");
      sign.dataset.required = "";
      sign.style.color = "red";
      sign.innerText = "*";

      const label = el.querySelector("label,legend");
      if (label) {
        label.appendChild(sign);
      }
    }
  },
  update: function (el, binding, vnode) {
    const add_sign = binding.value == undefined || binding.value;

    const label = el.querySelector("label,legend");
    if (label) {
      const sign = label.querySelector("[data-required]");
      if (sign && !add_sign) {
        sign.remove();
      } else if (!sign && add_sign) {
        let sign = document.createElement("span");
        sign.dataset.required = "";
        sign.style.color = "red";
        sign.innerText = "*";
        label.appendChild(sign);
      }
    }
  },
});
