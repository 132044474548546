import Vue from "vue";
import VueRouter from "vue-router";

import { Auth } from "@/api/common";

Vue.use(VueRouter);

//--------------------------------------------------------------------
// Dispatcher
//--------------------------------------------------------------------

let common = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "common" */ "@/views/Login.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: function () {
      Auth.logout();
      window.location.href = "/#/";
    },
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "common" */ "@/views/Registration.vue"),
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () =>
      import(/* webpackChunkName: "common" */ "@/views/Notifications.vue"),
  },
  {
    path: "/payed_orders",
    name: "payed_orders",
    component: () =>
      import(/* webpackChunkName: "common" */ "@/views/PayedOrders.vue"),
  },
  {
    path: "/messages",
    name: "messages",
    component: () =>
      import(/* webpackChunkName: "common" */ "@/views/Messages.vue"),
  },
   {
     path: "/map",
     name: "map",
    component: () =>
      import(/* webpackChunkName: "map" */ "@/views/InfographicMap"),
   },
  {
    path: "/success-pay",
    name: "success-pay",
    component: () =>
      import(/* webpackChunkName: "common" */ "@/views/SuccessPay.vue"),
  },
];

//--------------------------------------------------------------------
// Test
//--------------------------------------------------------------------
let test = [
  // {
  //   path: "/test/ws",
  //   component: () =>
  //     import(/* webpackChunkName: "test" */ "/src/views/test/ws.vue"),
  // },
  {
    path: "/test/forms",
    component: () =>
      import(/* webpackChunkName: "test" */ "/src/views/test/forms.vue"),
  },
  // {
  //   path: "/test/upload",
  //   component: () =>
  //     import(/* webpackChunkName: "test" */ "/src/views/test/upload.vue"),
  // },
  // {
  //   path: "/test/timetable",
  //   component: () =>
  //     import(/* webpackChunkName: "test" */ "/src/views/test/timetable.vue"),
  // },
  // {
  //   path: "/test/neworder",
  //   name: "neworder",
  //   component: () =>
  //     import(/* webpackChunkName: "test" */ "/src/views/test/neworder.vue"),
  // },
  // {
  //   path: "/test/easytable",
  //   name: "easytable",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "test" */ "/src/views/test/DataTable/easytable.jsx"
  //     ),
  // },
  // {
  //   path: "/test/dt-next",
  //   name: "dt-next",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "test" */ "/src/views/test/DataTable-next/wrapper.vue"
  //     ),
  // },
  // {
  //   path: "/test/misc",
  //   name: "misc",
  //   component: () =>
  //     import(/* webpackChunkName: "test" */ "/src/views/test/misc.vue"),
  // },
  // {
  //   path: "/test/chat",
  //   name: "misc",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "test" */ "/src/views/test/chat/ChatList.vue"
  //     ),
  // },
];

const legal = [
  {
    path: "/legal/private",
    props: { id: "legal/private" },
    component: () =>
      import(/* webpackChunkName: "articles" */ "/src/components/Article.vue"),
  },
  {
    path: "/legal/rules",
    props: { id: "legal/rules" },
    component: () =>
      import(/* webpackChunkName: "articles" */ "/src/components/Article.vue"),
  },
];

const docs = [
  {
    path: "/docs/about_apk",
    props: { id: "docs/about_apk" },
    component: () =>
      import(/* webpackChunkName: "articles" */ "/src/components/Article.vue"),
  },
];

const routes = [].concat(common, legal, docs);
if (process.env.NODE_ENV === "development") {
  routes.push(...test);
}

const router = new VueRouter({
  routes,
});

export default router;
