import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import ru from "vuetify/lib/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
  treeShake: true,
  lang: {
    locales: { ru },
    current: "ru",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#630000",
        // secondary: "#fef2d7",
        // accent: "#fef2d7",
        // error: "#fef2d7",
        background: "#fef2d7",
      },
    },
  },
  breakpoint: {
    mobileBreakpoint: "xs",
  },
});
