import Vue from "vue";
import { createPinia, defineStore, PiniaVuePlugin } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

Vue.use(PiniaVuePlugin);

export const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const defaultAppTitle = "�����������";

interface UserInfo {
  userfio: string;
  postname: string;
}

interface OrderInfo {
  new: boolean;
  updated: boolean;
  nmbr: number;

  service_name: string;
  cost: number;
  kol: number;
  sum_pay: number;
  sost_name: string;
  demand_sost: "0" | "1";
  cli_name: string;
  contract_num: string;
  date_doc: string;
  work_d_t: string;
  plan_latitude: number;
  plan_longitude: number;
  d_o: string;
  t_o: string;
  created_by: "customer" | "dispatcher";

  formname: string;
  route: string;
  t_service: number;
  t_obj_name: string;
  mobile: string;
}

interface Params {
  formname?: string;
  route: string;
  filter?: any;
}

interface StoreState {
  app: {
    title: string,
    side_menu_visible: boolean,
  }
  authorised: boolean;
  user_info: UserInfo;
  form_options: { [formname: string]: { itemsPerPage: number } };
  layout: "default";
  pending_orders: { orders: OrderInfo[]; params: Params };
  payed_orders: { orders: OrderInfo[]; params: Params };
  messages: any[];
  error_queue: any[];
}

const _useStore = defineStore("store", {
  persist: true,
  state(): StoreState {
    return {
      app: {
        title: defaultAppTitle,
        side_menu_visible: true,
      },
      authorised: false,
      user_info: { userfio: "", postname: "" },
      form_options: {},
      layout: "default",
      pending_orders: {
        orders: [],
        params: {
          formname: "",
          route: "",
          filter: {},
        },
      },
      payed_orders: {
        orders: [],
        params: {
          formname: "",
          route: "",
          filter: {},
        },
      },
      messages: [],
      error_queue: [],
    };
  },
  getters: {
    staff(): string {
      let { userfio, postname } = this.user_info;
      if (postname) {
        return `${userfio} (${postname})`;
      }

      return userfio;
    },
    getFormOptions(): (formname: string) => { itemsPerPage: number } {
      return (formname) => this.form_options[formname] || { itemsPerPage: 100 };
    },
  },
  actions: {
    setAppTitle(title) {
      this.app.title = title;
    },
    resetAppTitle() {
      this.setAppTitle(defaultAppTitle);
    },
    showSideMenu() {
      this.app.side_menu_visible = true;
    },
    hideSideMenu() {
      this.app.side_menu_visible = false;
    },
    logout() {
      this.setAuthorised(false);
    },
    setUserInfo(data) {
      let layout = data.rolename;
      if (!layout) {
        layout = "default";
      } else if (layout == "administrator") {
        layout = "dispatcher";
      }

      let { result, status, ...user_info } = data;

      this.layout = layout;
      this.user_info = user_info;
    },
    setAuthorised(value) {
      this.authorised = value;
      if (!value) {
        this.$reset();
      }
    },
    setFormOptions({ formname, options }) {
      if (!formname) {
        return;
      }
      if (!this.form_options[formname]) {
        this.form_options[formname] = { itemsPerPage: 0 };
      }
      if (this.form_options[formname].itemsPerPage != options.itemsPerPage) {
        this.form_options[formname].itemsPerPage = options.itemsPerPage;
      }
    },
    setLayout(value) {
      this.layout = value;
    },
    setPendingOrders(pending_orders) {
      this.pending_orders = pending_orders;
    },
    setMessages(messages) {
      this.messages = messages;
    },
    setPayedOrders(payed_orders) {
      this.payed_orders = payed_orders;
    },
    postMessageQueue(message) {
      this.error_queue.push(message);
    },
  },
});

export const useStore = () => _useStore(pinia);
